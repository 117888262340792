<template>
  <div class="page-user c-rel">
    <div class="page-user__logo">
      <img src="@/assets/images/compang-logo-s.png" alt="YOLOLIV" />
    </div>
    <div class="page-user__inner">
      <y-row>
        <y-col class="page-user__banner-wrap" :xs="24" :sm="12">
          <div class="page-user__banner">
            <y-carousel
              height="588px"
              :autoplay="false"
              :class="{ 'page-user__banner--disabled': list.length === 1 }"
              :indicator-position="list.length === 1 ? 'none' : ''"
            >
              <y-carousel-item v-for="(item, i) in list" :key="i">
                <img class="page-user__banner-img" :src="item" alt="banner" />
              </y-carousel-item>
            </y-carousel>
          </div>
        </y-col>
        <y-col class="page-user__main-wrap" :xs="24" :sm="12">
          <div class="page-user__main">
            <router-view></router-view>
          </div>
        </y-col>
      </y-row>
    </div>
  </div>
</template>

<script>
import banner from '@/assets/images/banner-login-1.png'

export default {
  data() {
    return {
      list: [banner]
    }
  }
}
</script>

<style lang="scss" scoped>
.page-user {
  height: 100vh;
  background: rgba(0, 0, 0, 0.04);
}
.page-user__logo {
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 30px;
  }
}
.page-user__inner {
  width: 1040px;
  height: 588px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.page-user__banner {
  height: 588px;
  background: pink;
}
.page-user__main {
  height: 588px;
  padding: 60px 60px 48px 60px;
  background: #fff;
}
.page-user__banner-img {
  width: 100%;
  height: 100%;
}

.page-user__banner--disabled {
  ::v-deep .el-carousel__arrow {
    display: none !important;
  }
}

@media screen and (max-width: 1040px) {
  .page-user__inner {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .page-user__inner {
    width: 90%;
    position: static;
    transform: unset;
    margin: 40px auto 0;
  }
  .page-user__main {
    padding: 20px;
    height: auto;
  }
  .page-user__banner-wrap {
    display: none;
  }
}
</style>
